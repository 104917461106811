import React from 'react'
import { Link } from 'gatsby'
import Fade from "react-reveal/Fade"

// import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useSelector, useDispatch } from 'react-redux'
import { workDetailSlug } from '../actions'

const WorkGrid = (props) => {
    let seeMoreWorkString = "See more work"
    const langKey = useSelector(state => state.langKey.language)
    let urlBase
    if(process.env.GATSBY_OFFICE_NAME === 'WK_SEOUL'){
        urlBase = langKey === "ko" ? "" : `/${langKey}`
    }
    else{
        urlBase = langKey === "ja" ? "" : `/${langKey}`
    }
    const dispatch = useDispatch()    
    return(
        <Fade bottom speed={450} delay={25} distance="15%">
        <article className={`thumb ${props.class}`} data-index={props.index}>
            <div className="thumb__Img">
                <img src={props.thumb} srcSet={props.thumbSrcSet} alt="" />
                {/* <StaticImage src={props.thumb} alt="" /> */}
                {/* <GatsbyImage image={getImage(props.thumb)} alt="" /> */}
            </div>
            <div className="thumb__Desc" style={{backgroundColor: `#${props.bgColor}`}}>
                <h2 dangerouslySetInnerHTML={{__html:props.client + " - " + props.title}} />
                <p>{props.description}</p>
                <Link to={`${urlBase}/${props.section}/${props.url}`} onClick={()=> dispatch(workDetailSlug(props.url))} className="thumb__Overlay__Link">View</Link>
            </div>
        </article>
        </Fade>
    )
}


export default WorkGrid