import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
// import Fade from "react-reveal/Fade"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../layouts/App"
import SEO from "../components/seo"

import WorkGrid from '../components/WorkGrid'

export default ({pageContext}) => {
    return(
        <Layout>
            <SEO title="Work" description={pageContext.node.acf.metaPageDescription}/>
            <article>
                <section className="container-fluid-custom-only work_page">
                    <StaticQuery query={graphql`
                            {
                                languageJA: allWpWork(filter: {languageCode: {eq: "ja"}, related_office: {relatedOffice: {ne: "wkseoul"}}}, sort: { fields: [date], order: DESC }) {
                                    edges {
                                        node {
                                            id
                                            title
                                            slug
                                            languageCode
                                            acf {
                                            workExcerpt
                                            workImage {
                                                sourceUrl
                                                srcSet
                                                mediaDetails {
                                                    sizes {
                                                      name
                                                      sourceUrl
                                                    }
                                                }
                                            }
                                            launchDate
                                            }
                                            workInfo {
                                            client
                                            }
                                            related_office {
                                                relatedOffice
                                            }
                                        }
                                    }
                                }
                                languageEN: allWpWork(filter: {languageCode: {eq: "en"}}, sort: { fields: [date], order: DESC }) {
                                    edges {
                                        node {
                                            id
                                            title
                                            slug
                                            languageCode
                                            acf {
                                            workExcerpt
                                            workImage {
                                                sourceUrl
                                                srcSet
                                                mediaDetails {
                                                    sizes {
                                                      name
                                                      sourceUrl
                                                    }
                                                }
                                            }
                                            launchDate
                                            }
                                            workInfo {
                                            client
                                            }
                                            related_office {
                                                relatedOffice
                                              }
                                        }
                                    }
                                }
                                languageKO: allWpWork(filter: {languageCode: {eq: "ko"}, related_office: {relatedOffice: {ne: "wktokyo"}}}, sort: { fields: [date], order: DESC }) {
                                    edges {
                                        node {
                                            id
                                            title
                                            slug
                                            languageCode
                                            acf {
                                            workExcerpt
                                            workImage {
                                                sourceUrl
                                                srcSet
                                                mediaDetails {
                                                    sizes {
                                                      name
                                                      sourceUrl
                                                    }
                                                }
                                            }
                                            launchDate
                                            }
                                            workInfo {
                                            client
                                            }
                                            related_office {
                                                relatedOffice
                                              }
                                        }
                                    }
                                }
                            }
                        `}
                        render={ props =>{
                            if(pageContext.node.languageCode === 'ja'){
                                let workImagery
                                return(
                                    <div className="grids">
                                        {props.languageJA.edges.map((work, index) => {
                                            let gridClassName = [], gridBgColor = [], gridIndex = index
                                            if(gridIndex % 7 === 0) {
                                                gridClassName.push(`doubleSize`)
                                                gridBgColor.push(`ff4a94`)
                                            }
                                            else if(gridIndex % 7 === 3) {
                                                gridClassName.push(`fullWidth`)
                                                gridBgColor.push(`ff86b8`)
                                            }
                                            else{
                                                gridClassName.push(`standard`)
                                            }
                                            {work.node.acf.workImage.mediaDetails.sizes.map(imageSrc => {
                                                for(let i=0; i<5; i++){
                                                    if(imageSrc.name === 'large'){
                                                        return workImagery = imageSrc.sourceUrl
                                                        i = 4;
                                                    }else if(imageSrc.name === 'medium') {
                                                        return workImagery = imageSrc.sourceUrl
                                                        i = 4;
                                                    }  
                                                    i++;                                             
                                                }
                                            })}
                                            return(
                                                <WorkGrid
                                                    index={index}
                                                    key={work.node.id}
                                                    section="work"
                                                    class={gridClassName}
                                                    thumb={work.node.acf.workImage.sourceUrl}
                                                    thumbSrcSet={work.node.acf.workImage.srcSet}
                                                    title={work.node.title}
                                                    client={work.node.workInfo.client}
                                                    description={work.node.acf.workExcerpt}
                                                    bgColor={gridBgColor}
                                                    url={work.node.slug}
                                                />
                                            )
                                        })
                                    }
                                    </div>
                                )
                            }
                            if(pageContext.node.languageCode === 'en'){
                                let workImagery
                                return(
                                    <div className="grids">
                                        {props.languageEN.edges.map((work, index) => {
                                            let gridClassName = [], gridBgColor = [], gridIndex = index
                                            if(gridIndex % 7 === 0) {
                                                gridClassName.push(`doubleSize`)
                                                gridBgColor.push(`ff4a94`)
                                            }
                                            else if(gridIndex % 7 === 3) {
                                                gridClassName.push(`fullWidth`)
                                                gridBgColor.push(`ff86b8`)
                                            }
                                            else{
                                                gridClassName.push(`standard`)
                                            }
                                            if(process.env.GATSBY_OFFICE_NAME === 'WK_SEOUL'){
                                                if(work.node.related_office.relatedOffice !== 'wktokyo'){
                                                    {work.node.acf.workImage.mediaDetails.sizes.map(imageSrc => {
                                                        for(let i=0; i<5; i++){
                                                            if(imageSrc.name === 'large'){
                                                                return workImagery = imageSrc.sourceUrl
                                                                i = 4;
                                                            }else if(imageSrc.name === 'medium') {
                                                                return workImagery = imageSrc.sourceUrl
                                                                i = 4;
                                                            }  
                                                            i++;                                             
                                                        }
                                                    })}
                                                    return(
                                                        <WorkGrid
                                                            index={index}
                                                            key={work.node.id}
                                                            section="work"
                                                            class={gridClassName}
                                                            thumb={work.node.acf.workImage.sourceUrl}
                                                            thumbSrcSet={work.node.acf.workImage.srcSet}
                                                            title={work.node.title}
                                                            client={work.node.workInfo.client}
                                                            description={work.node.acf.workExcerpt}
                                                            bgColor={gridBgColor}
                                                            url={work.node.slug}
                                                        />
                                                    )
                                                }
                                            }
                                            else{
                                                if(work.node.related_office.relatedOffice !== 'wkseoul'){
                                                    {work.node.acf.workImage.mediaDetails.sizes.map(imageSrc => {
                                                        for(let i=0; i<5; i++){
                                                            if(imageSrc.name === 'large'){
                                                                return workImagery = imageSrc.sourceUrl
                                                                i = 4;
                                                            }else if(imageSrc.name === 'medium') {
                                                                return workImagery = imageSrc.sourceUrl
                                                                i = 4;
                                                            }  
                                                            i++;                                             
                                                        }
                                                    })}
                                                    return(
                                                        <WorkGrid
                                                            index={index}
                                                            key={work.node.id}
                                                            section="work"
                                                            class={gridClassName}
                                                            thumb={work.node.acf.workImage.sourceUrl}
                                                            thumbSrcSet={work.node.acf.workImage.srcSet}
                                                            title={work.node.title}
                                                            client={work.node.workInfo.client}
                                                            description={work.node.acf.workExcerpt}
                                                            bgColor={gridBgColor}
                                                            url={work.node.slug}
                                                        />
                                                    )
                                                }
                                            }
                                        })
                                    }
                                    </div>
                                )
                            }
                            if(pageContext.node.languageCode === 'ko'){
                                let workImagery
                                return(
                                    <div className="grids">
                                        {props.languageKO.edges.map((work, index) => {
                                            let gridClassName = [], gridBgColor = [], gridIndex = index
                                            if(gridIndex % 7 === 0) {
                                                gridClassName.push(`doubleSize`)
                                                gridBgColor.push(`ff4a94`)
                                            }
                                            else if(gridIndex % 7 === 3) {
                                                gridClassName.push(`fullWidth`)
                                                gridBgColor.push(`ff86b8`)
                                            }
                                            else{
                                                gridClassName.push(`standard`)
                                            }
                                            {work.node.acf.workImage.mediaDetails.sizes.map(imageSrc => {
                                                for(let i=0; i<5; i++){
                                                    if(imageSrc.name === 'large'){
                                                        return workImagery = imageSrc.sourceUrl
                                                        i = 4;
                                                    }else if(imageSrc.name === 'medium') {
                                                        return workImagery = imageSrc.sourceUrl
                                                        i = 4;
                                                    }  
                                                    i++;                                             
                                                }
                                            })}
                                            return(
                                                <WorkGrid
                                                    index={index}
                                                    key={work.node.id}
                                                    section="work"
                                                    class={gridClassName}
                                                    thumb={work.node.acf.workImage.sourceUrl}
                                                    thumbSrcSet={work.node.acf.workImage.srcSet}
                                                    title={work.node.title}
                                                    client={work.node.workInfo.client}
                                                    description={work.node.acf.workExcerpt}
                                                    bgColor={gridBgColor}
                                                    url={work.node.slug}
                                                />
                                            )
                                        })
                                    }
                                    </div>
                                )
                            }
                        }
                        } />
                    <div className="clearfix"></div>
                </section>
            </article>
        </Layout>
    )
}